// ===============================================================================================
//  File Name: error.scss
//  Description: Page content different user error page layouts SCSS.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Frest HTML Admin Template
//  Version: 1.0
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

/* Error Css */
/* --------- */

.error-title {
  font-size: 3rem;
}

// Media Query For Small Screen
@media only screen and (max-width: 575px) {
  .error-title {
    font-size: 2rem;
  }
}
