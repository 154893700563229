// =========================================================================================
//   File Name: avatar.scss
//   Description: Avatar images.
//   ----------------------------------------------------------------------------------------
//   Item Name: Frest HTML Admin Template
//   Version: 1.0
//   Author: PIXINVENT
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

/* Avatar Css */
/* ---------- */

// Avatar
.avatar {
  white-space: nowrap;
  background-color: $avatar-bg;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  color: $white;
  display: inline-flex;
  font-size: 0.8rem;
  text-align: center;
  vertical-align: middle;
  margin: 5px;

  // Avatar Content
  .avatar-content {
    width: $avatar-size;
    height: $avatar-size;
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar-icon {
      font-size: 1.2rem;
    }
  }
  [class*="avatar-status-"] {
    border-radius: 50%;
    width: $avatar-status-size;
    height: $avatar-status-size;
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid $white;
    &.avatar-status-lg {
      width: $avatar-status-size-lg;
      height: $avatar-status-size-lg;
      border-width: 2px;
    }
  }
  .avatar-status-online {
    background-color: $success;
  }
  .avatar-status-busy {
    background-color: $danger;
  }
  .avatar-status-away {
    background-color: $warning;
  }
  .avatar-status-offline {
    background-color: $secondary;
  }

  // Avatar Img
  img {
    border-radius: 50%;
  }

  // Avatar Sizes
  &.avatar-xl {
    font-size: 1.5rem;
    img {
      width: $avatar-size-xl;
      height: $avatar-size-xl;
    }
    .avatar-content {
      height: $avatar-size-xl;
      width: $avatar-size-xl;
      .avatar-icon {
        font-size: 3rem;
      }
    }
  }
  &.avatar-lg {
    font-size: 1.2rem;
    img {
      width: $avatar-size-lg;
      height: $avatar-size-lg;
    }
    .avatar-content {
      width: $avatar-size-lg;
      height: $avatar-size-lg;
      .avatar-icon {
        font-size: 2rem;
      }
    }
  }

  &.avatar-sm {
    .avatar-content {
      width: $avatar-size-sm;
      height: $avatar-size-sm;
      .avatar-icon {
        font-size: 1rem;
      }
    }
    img {
      width: $avatar-size-sm;
      height: $avatar-size-sm;
    }
  }
}
