// =========================================================================================
//     File Name: demo.scss
//     Description: CSS used for demo purpose only. Remove this css from your project.
//     ----------------------------------------------------------------------------------------
//     Item Name: Frest HTML Admin Template
//     Version: 1.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

/* Demo Css */
/* -------- */

// Font Specific Page Css
.fonticon-container {
  > .fonticon-wrap {
    float: left;
    width: 60px;
    height: 60px;
    text-align: center;
    margin-bottom: 1rem;
    > i {
      font-size: 2.28rem;
      transition: all 0.2s ease-in-out;
    }
  }
  &:hover {
    i {
      color: $primary;
      font-size: 2.9rem;
      transform: scale(1.1);
    }
  }
  > .fonticon-classname,
  > .fonticon-unit {
    display: block;
    font-size: 1.2rem;
    text-transform: lowercase;
    margin-top: 0.3rem;
    font-weight: 400;
  }
}
// Livicons Specific Page Css
.font-animated {
  .fonticon-classname {
    text-transform: lowercase;
    font-weight: 400;
  }
}

.browser {
  background: #e0e0e0;
  border: 4px solid #e0e0e0;
  width: 100%;
  height: 12rem;
  padding-top: 20px;
  margin: 0 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.browser iframe {
  border: 0;
  background: $white;
  height: 100%;
  width: 100%;
}

.loader-wrapper {
  height: 8em;
}

.maintenance-icon {
  font-size: 4rem;
}

.animation-icon {
  right: 30px;
  bottom: 10px;
}

.header-navbar {
  &.navbar-demo {
    background-color: $body-bg;
  }
}
