// =========================================================================================
// 	File Name: divider.scss
// 	Description: Divider css.
// 	----------------------------------------------------------------------------------------
// 	Item Name: Frest HTML Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

/* Divider Css */
/* --------- */

.divider {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  margin: 1rem 0;

  // Divider Text
  .divider-text {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    padding: 0 1rem;
    background-color: $white;

    // Divider Icon
    i {
      font-size: 1rem;
    }

    // Divider Border
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 9999px;
      border-top: 1px solid $border-color;
    }

    // Divider Spacing
    &:before {
      right: 100%;
    }
    &:after {
      left: 100%;
    }
  }

  // Divider Positions

  &.divider-left {
    .divider-text {
      float: left;
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }

  &.divider-left-center {
    .divider-text {
      left: -25%;
    }
  }

  &.divider-right {
    .divider-text {
      float: right;
      padding-right: 0;
      &:after {
        display: none;
      }
    }
  }

  &.divider-right-center {
    .divider-text {
      right: -25%;
    }
  }

  // Divider Styles

  &.divider-dotted {
    .divider-text {
      &:before,
      &:after {
        border-style: dotted;
        border-width: 1px;
        border-top-width: 0;
        border-color: rgba($black, 1);
      }
    }
  }
  &.divider-dashed {
    .divider-text {
      &:before,
      &:after {
        border-style: dashed;
        border-width: 1px;
        border-top-width: 0;
        border-color: rgba($black, 1);
      }
    }
  }
}
