// =========================================================================================
//     File Name: sidebar.scss
//     Description: content sidebar specific scss.
//     ----------------------------------------------------------------------------------------
//     Item Name: Frest HTML Admin Template
//     Version: 1.0
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================

/* Sidebar */
/* ------ */

.sidebar {
  position: relative;
  width: 100%;

  // Media Query For Large Screen
  @include media-breakpoint-up(lg) {
    vertical-align: top;
  }
}
/* Sidebar Fixed */
.sidebar-fixed {
  position: fixed;
  height: 100%;
  overflow: scroll;
}

/* Sidenav Overlay */
.sidenav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 120vh;
  background-color: rgba($black, 0.5);
  z-index: 997;
  display: none;
}

/* Drag Target */
.drag-target {
  height: 100%;
  width: 30px;
  position: fixed;
  top: 0;
  left: -10px;
  z-index: 1036;
}

// Media Query For Large Screen
@include media-breakpoint-up(lg) {
  .sidebar-left {
    float: left;
  }
  .sidebar-right {
    float: right;
  }
}
